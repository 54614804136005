html {
    background-color: #f8f9fa;
}

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.nav-link:hover,
.active-link,
.active-link:hover,
.active-link:focus {
    color: var(--bs-primary);
}

.btn-outline-secondary {
    border: var(--bs-border-width) solid var(--bs-border-color);
}

.react-datepicker__day--keyboard-selected {
    background-color: var(--bs-primary);
    color: var(--bs-text-body);
}

.react-datepicker__day--selected {
    background-color: var(--bs-primary);
    color: var(--bs-text-body);
}

.react-datepicker__day:hover {
    background-color: var(--bs-primary-bg-subtle);
}

.react-datepicker {
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-radius: var(--bs-border-radius);
}

.react-datepicker-wrapper {
    width: 100%;
}

#yearDropdown span {
    color: var(--bs-body-color);
}

#yearDropdown:hover span,
#yearDropdown:focus span,
#yearDropdown:active span,
#yearDropdown[aria-expanded="true"] span {
    color: var(--bs-text-light);
}

.barcode-font {
    font-family: "Libre Barcode 128 Text";
    font-size: 24pt;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 14pt;
}

.h1 {
    font-size: 2rem;
    font-weight: 600;
}

.h2 {
    font-size: 1.75rem;
    font-weight: 500;
}

.h3 {
    font-size: 1.5rem;
    font-weight: 500;
}

.h4 {
    font-size: 1.25rem;
    font-weight: 500;
}

.toast-body,
.toast-header {
    background-color: white !important;
}

.shadow-custom {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.navbar-toggler {
    border: none;
    box-shadow: none !important;
}

.apexcharts-svg {
    overflow: visible;
}

.apexcharts-toolbar {
    z-index: 0 !important;
}
